import React from 'react'
import { useParams } from 'react-router-dom';
import { useShopify } from '../hooks';
import { Grid } from '@material-ui/core';
import Footer from './Footer';
import Product from './Product';
import { Button } from './ButtonElement';
import { Link } from 'react-router-dom';
import Cart from './Cart';
import NewNav from './NewNav';



const Placeholder = () => {
	const getNumberOfItems = () => {
		const width = window.innerWidth;

		if (width < 960) return 1;
		if (width < 1280) return 1;
		return 1;
	};

	const numberedArray = Array.from(Array(getNumberOfItems()).keys());

	return numberedArray.map((number) => (
		<Grid item key={number}>
			<div className="loading_block" />
		</Grid>
	));
};

const ProductLanding = (props) => {
	const { products } = useShopify();
	const params = useParams();

	return (
		<>
			<div>
				<NewNav />
				<div className="App__header">
					<div className="carousel-image">
						<img src="/././Assets/banner.jpg" alt="Cloud Merch Store" />
					</div>

				</div>

				<div className="App__title">
					<h1 className="App__title-text">The Official Cloud Clothing Store</h1>
				</div>
				<div className="product-sort-buttons">
					<Link style={{ textDecoration: 'none' }} to="/">
						<Button className="sort-buttons">View All</Button>
					</Link>
				</div>
			</div>
			<div className="Products-wrapper">
				<div className="Products-wrapper__inner" style={{ maxWidth: '425px' }}>
					{products && products.length > 0 ? (
						products.filter(product => product.handle === params.id).map((item) => (
							<Product product={item} history={props.history} />
						))
					) : (
						<Placeholder />
					)}
				</div>
			</div >


			<Footer />
		</>
	)
}

export default ProductLanding;